<footer class="footer-area">
    <div class="container">
        <h3><a routerLink="/"><span>G7 </span>Soluciones</a></h3>
        <ul>
            <li><a href="#" target="_blank" class="fab fa-facebook-f"></a></li>
            <li><a href="http://www.zonaradiofm.com" target="_blank" class="fab fa-globe"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
        </ul>
        <p>Copyright <i class="far fa-copyright"></i>2021 All Rights Reserved.</p>
    </div>
</footer>

<!-- <app-demo-sidebar></app-demo-sidebar> -->

<ngx-scrolltop></ngx-scrolltop>
