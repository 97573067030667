<div class="strategy-area">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="strategy-image">
                    <img src="assets/img/about-strategy.png" alt="image">
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="strategy-content ptb-70">
                    <div class="section-title">
                        <h4>Soluciones altamente creativas</h4>
                        <h2>Sobre Nuestra <span>Estrategia</span></h2>
                        <p>nuestra mision es satisfacer las necesidades de nuestros cliente, enfocandonos en el bienestar y/o beneficios para el mismo.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fa fa-check"></i>Planteamiento</li>
                        <li><i class="fa fa-check"></i>Requerimientos Priorizados </li>
                        <li><i class="fa fa-check"></i>Iteraciones</li>
                        <li><i class="fa fa-check"></i>Puesta en producci&oacute;n</li>
                        <!-- <li><i class="fa fa-check"></i>Pruebas</li>
                        <li><i class="fa fa-check"></i>Digital Marketing & Branding</li> -->
                    </ul>
                    <p>utilizamos las metodolog&iacute;as &aacute;giles de desarrollo de software, estas son las m&aacute;s utilizadas hoy en día debido a su alta flexibilidad y agilidad. Los equipos de trabajo que las utilizan son mucho m&aacute;s productivos y eficientes, ya que saben lo que tienen que hacer en cada momento.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</div>
