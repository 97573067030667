<div class="why-we-different ptb-100 bg-F7F5F4">
    <div class="container">
        <div class="section-title">
            <h2>Porque somos <span>Diferentes</span></h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
        </div>
        <div class="tabset why-we-different-tabset">
            <!-- Tab 1 -->
            <input type="radio" name="tabset" id="tab1" aria-controls="speed-flexibility" checked>
            <label for="tab1">rapidez , flexibilidad</label>
            <!-- Tab 2 -->
            <input type="radio" name="tabset" id="tab2" aria-controls="profeesional-work">
            <label for="tab2">Trabajo profesional</label>
            <!-- Tab 3 -->
            <!-- <input type="radio" name="tabset" id="tab3" aria-controls="experienced-staff">
            <label for="tab3">Experienced Staff</label> -->
            <!-- Tab 4 -->
            <input type="radio" name="tabset" id="tab4" aria-controls="custom-support">
            <label for="tab4">Soporte Personalizado</label>
            <div class="tab-panels">
                <div id="speed-flexibility" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Rapidos Y flexibles</h3>
                                <p>nos enfocamos en finalizar con rapidez y eficiencias nuestras tareas, segun el cliente se comprometa de igual manera, dandole flexibilidad.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Requerimientos a tiempo</li>
                                    <li><i class="fas fa-check"></i> Realizacion de Pruebas</li>
                                    <li><i class="fas fa-check"></i> Compromiso</li>
                                    <li><i class="fas fa-check"></i> Responsabilidad</li>
                                    <li><i class="fas fa-check"></i> Entrega</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="profeesional-work" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img2.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Trabajo profesional</h3>
                                <p>Nuestro equipo esta altamente capacitado y preparado para brindar un ejemplar servicio.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Eticos</li>
                                    <li><i class="fas fa-check"></i> Motivados</li>
                                    <li><i class="fas fa-check"></i> Objetivos</li>
                                    <li><i class="fas fa-check"></i> Capacitados</li>
                                    <!-- <li><i class="fas fa-check"></i> Awesome Design</li> -->
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div id="experienced-staff" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img1.jpg" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Experienced staff</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Creative Design</li>
                                    <li><i class="fas fa-check"></i> Retina Ready</li>
                                    <li><i class="fas fa-check"></i> Responsive Design</li>
                                    <li><i class="fas fa-check"></i> Modern Design</li>
                                    <li><i class="fas fa-check"></i> Awesome Design</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div> -->
                <div id="custom-support" class="tab-panel">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-img">
                                <img src="assets/img/different-img3.png" alt="img">
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="why-we-different-content">
                                <h3>Soporte</h3>
                                <p>Instalar y configurar la tecnología a ser empleada en la empresa, es decir, los equipos, sistemas operativos, programas y aplicaciones.</p>
                                <ul>
                                    <li><i class="fas fa-check"></i> Mantenimiento </li>
                                    <li><i class="fas fa-check"></i> Diagnosticos</li>
                                    <li><i class="fas fa-check"></i> Backups</li>
                                    <li><i class="fas fa-check"></i> Realizar Pruebas</li>
                                    <li><i class="fas fa-check"></i> Revisiones de seguridad</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
