<div class="main-banner item-bg-one">
    <div class="creative-banner-three"></div>
    <particles [params]="myParams" [style]="myStyle" [width]="width" [height]="height"></particles>
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <h4>G7 Soluciones</h4>
                    <h1>Somos
                        <ngx-typed-js [strings]="['Profesionales', 'Creativos', 'Diseñadores', 'Unicos', 'Desarrollo', 'Soporte']" [typeSpeed]="100" [loop]="true" [backSpeed]="100" [showCursor]="false">
                            <span class="typing"></span>
                        </ngx-typed-js>
                    </h1>
                    <p>Con el mejor equipo de desarrollo de aplicaciones, Soportes tecnicos.</p>
                    <a (click)="onClick('about')" class="btn btn-primary">Nosotros</a>
                    <a (click)="onClick('work')" class="btn btn-primary view-work">Projectos</a>
                </div>
            </div>
        </div>
    </div>
</div>

<app-welcome></app-welcome>

<app-about></app-about>

<app-strategy></app-strategy>
<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7279534511541114"
     crossorigin="anonymous"></script>
<ins class="adsbygoogle"
     style="display:block"
     data-ad-format="fluid"
     data-ad-layout-key="-eu-1i+53-oe+kq"
     data-ad-client="ca-pub-7279534511541114"
     data-ad-slot="3095224246"></ins>
<script>
     (adsbygoogle = window.adsbygoogle || []).push({});
</script>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>

<app-services></app-services>

<app-why-we-different></app-why-we-different>

<app-work></app-work>

<!-- <app-pricing></app-pricing> -->

<!-- <app-faq></app-faq> -->

<app-feedback></app-feedback>

<!-- <app-blog></app-blog> -->

<app-partner></app-partner>

<!-- <app-subscribe></app-subscribe> -->

<app-contact></app-contact>
