<div class="who-we-are-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Quienes <span>Somos</span></h2>
            <p>Somos una empresa que ofrece un excelente servicio para todos los sectores empresariales y personales.
                Fundada por especialistas con m&aacute;s de 12 a&ntilde;os de experiencia en inform&aacute;tica y otras &aacute;reas adyacentes.
                En G7 Soluciones nos preocupamos por brindar soluciones que agreguen valor a nuestros clientes,
                materializando la capacidad de innovar a un ritmo constante, la aplicaci&oacute;n de las buenas pr&aacute;cticas y la excelencia profesional
                 en todos los proyectos que hacemos.</p>
        </div>
        <div class="row">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-users"></i>
                    <h3>Profesionales</h3>
                    <!-- <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p> -->
                    <span>1</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-lightbulb"></i>
                    <h3>Apacionados</h3>
                    <!-- <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p> -->
                    <span>2</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fas fa-tag"></i>
                    <h3>Creativos</h3>
                    <!-- <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p> -->
                    <span>3</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-hand-scissors"></i>
                    <h3>Dise&ntilde;adores</h3>
                    <!-- <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p> -->
                    <span>4</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="fab fa-linode"></i>
                    <h3>Unicos</h3>
                    <!-- <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p> -->
                    <span>5</span>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-who-we-are">
                    <i class="far fa-life-ring"></i>
                    <h3>Soportes</h3>
                    <!-- <p>Fringilla augue at maximus vest vestibulum. Nam pulvinar vitaessst neque et porttitor. Praesent sed nis.</p> -->
                    <span>6</span>
                </div>
            </div>
        </div>
    </div>
</div>
