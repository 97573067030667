<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h4>La creatividad vive en nosotros</h4>
            <h2>Bienvenidos a <span>G7 Soluciones</span></h2>
            <p>Somos un equipo de profesionales con amplia experiencia en el desarrollo de software.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-edit"></i>
                    </div>
                    <h3>Dise&ntilde;os Creativos</h3>
                    <p>El dise&ntilde;o creativo est&aacute; basado en el empleo de estrategias creativas para generar ideas que solucionen problemas.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="fas fa-laptop"></i>
                    </div>
                    <h3>Codigo Amigable</h3>
                    <p>Gracias a la experiencia de nuestros desarrolladores m&aacute;s al conocimiento de las &uacute;ltimas metodolog&iacute;as de desarrollo.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-box">
                    <div class="icon">
                        <i class="far fa-life-ring"></i>
                    </div>
                    <h3>Soporte R&aacute;pido y Eficiente</h3>
                    <p> Contamos con un personal comprometido y sumamente entrenado, apacionados por la tecnologia.</p>
                    <a (click)="onClick('about')" title="Read More" class="link-btn"><i class="fa fa-arrow-right"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
