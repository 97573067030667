<div id="about" class="about-area ptb-100">
    <div class="container">
        <div class="section-title">
            <h2>Sobre <span>G7 soluciones</span></h2>
            <p>Somos Desarrolladores de aplicaciones Web y moviles Android & IOS.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>Desarrolladores de Aplicaciones <span>Webs & Moviles</span></h2>
                        <p>Somos pioneros configurando emisoras Online, asi como desarrollando aplicaciones radiales.</p>
                    </div>
                    <ul class="features-list">
                        <li><i class="fas fa-check"></i>Aplicaciones Webs</li>
                        <li><i class="fas fa-check"></i>Moviles</li>
                        <li><i class="fas fa-check"></i> API's & Microservicios</li>
                        <li><i class="fas fa-check"></i>Sistemas de Informaci&oacute;n</li>
                        <li><i class="fas fa-check"></i>Emisoras Online</li>
                        <!-- <li><i class="fas fa-check"></i>Digital Marketing & Branding</li> -->
                    </ul>
                    <p>

                          Somos un equipo de profesionales con amplia experiencia en el desarrollo de software.

                    </p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <img src="assets/img/about.png" alt="about">
                    <!-- <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>
