<div class="skill-area ptb-100 bg-fffdfd">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="skill-content">
                    <div class="section-title">
                        <h2>Algunas de Nuestras <span>Habilidades</span></h2>
                        <p>Nuestro equipo posee basta experiencia y habilidades.</p>
                    </div>
                    <div class="skills-list">
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">Angular</h4>
                                <div class="skill-percentage">
                                    <div class="count-box"><span class="count-text">100</span>%</div>
                                </div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">nodeJS</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">100</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">MongoDB</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">100</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">JavaScript</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">100</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">SQL Server</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">100</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>

                        <div class="skill-item">
                            <div class="skill-header">
                                <h4 class="skill-title">.NET CORE, MVC</h4>
                                <div class="skill-percentage"><div class="count-box"><span class="count-text">100</span>%</div></div>
                            </div>
                            <div class="skill-bar">
                                <div class="bar-inner"><div class="bar progress-line" data-width="100"></div></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="skill-video">
                    <img src="assets/img/prog-lang.png" alt="video-img">
                    <!-- <div class="video-btn">
                        <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupTwo').open()"><i class="fas fa-play"></i></button>
                    </div> -->
                </div>
            </div>
        </div>
    </div>
</div>
<div class="video-popup">
    <ngx-smart-modal #popupTwo [identifier]="'popupTwo'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal>
</div>
