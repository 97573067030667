<!-- <div id="work" class="work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Estaa <span>Trabajos</span></h2>
            <p>Algunos de nuestros trabajos.</p>
        </div>
    </div> -->
    <!-- <div class="container-fluid">
        <div class="work-tabs">
            <ngx-tabset>
                <ngx-tab tabTitle="All">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> / </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> / </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>/</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>/</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>/</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>/</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Web Apps">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/radioantillas-work.png" alt="work-img">

                                <div class="work-content">
                                    <h3>Radiantillas</h3>
                                    <ul>
                                        <li><a routerLink="www.radioantillas.com" target="_blank">accede</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Sabor a Merengue</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Radio Online">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Radio antillas</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li> . </li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Sabor a Merengue</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Apps Moviles">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Games Design</h3>
                                    <ul>
                                        <li><a href="#">IT</a></li>
                                        <li> . </li>
                                        <li><a href="#">Games</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Photoshop">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

                <ngx-tab tabTitle="Illustrator">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-6">
                            <div class="single-work">
                                <img src="assets/img/work-img1.jpg" alt="work-img">
                                <div class="work-content">
                                    <h3>Creative Design</h3>
                                    <ul>
                                        <li><a routerLink="/">Design</a></li>
                                        <li>.</li>
                                        <li><a routerLink="/">Brand</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </ngx-tab>

            </ngx-tabset>
        </div>
    </div> -->
<!-- </div> -->


<section class="funfacts-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <!-- <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="far fa-smile"></i>
                    <h3><span [countUp]="40">00</span></h3>
                    <p>Clientes Felices</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-shield-alt"></i>
                    <h3><span [countUp]="300">00</span></h3>
                    <p>Projectos</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-award"></i>
                    <h3><span [countUp]="46">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
            <div class="col-lg-3 col-md-3 col-6 col-sm-3">
                <div class="funfact">
                    <i class="fas fa-user"></i>
                    <h3><span [countUp]="5">00</span></h3>
                    <p>Miembros del Equipo</p>
                </div>
            </div> -->
        </div>
    </div>
</section>
