<div class="partner-area ptb-70 bg-fffdfd">
    <div class="container">
        <div class="partner-slides">
            <owl-carousel-o [options]="partnerSlides">
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="radionatillas.com" target="_blank"><img src="assets/img/radioantillas.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="http://www.zonaradiofm.com" target="_blank"><img src="assets/img/zonaradiofm.jpg" alt="Zona Radio FM"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="saboramerengue.com" target="_blank"><img src="assets/img/logosaboramerengue.png " alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                           <a href="lacandentefm.com" target="_blank"><img src="assets/img/lacandenteFM140.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="royste.com" target="_blank"><img src="assets/img/royste140.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="rascacielosfm.com" target="_blank"><img src="assets/img/rascacielosfm.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="http://aperisima.com/" target="_blank"><img src="assets/img/aperisima140.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="http://corazonadasdigitales.com/" target="_blank"><img src="assets/img/corazonadasdigi.png" alt="partner"></a>
                    </div>
                </ng-template>
                <!-- <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-2.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-3.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-4.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-5.png" alt="partner"></a>
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="partner-item">
                        <a href="#" target="_blank"><img src="assets/img/partner-6.png" alt="partner"></a>
                    </div>
                </ng-template> -->
            </owl-carousel-o>
        </div>
    </div>
</div>
