<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Nuestros <span>Servicios</span></h2>
            <p>Ofrecemos un completo espectro de servicios para ayudar a que las organizaciones funcionen mejor.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/radio-online.png" alt="Emisoras Online">
                        <div class="icon">
                            <i class="fas fa-pencil-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Emisoras Online</h3>
                        <p>Somos expertos configurando emisoras online, asi como tambien la creacion de las aplicaciones moviles y webs que estas necesitan.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/web-developer.png" alt="Aplicaciones webs">
                        <div class="icon">
                            <i class="fab fa-linode"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Aplicaciones Webs</h3>
                        <p>Utilizamos los frameworks javascript de mejor prestigio en primer lugar Angular.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/mobile-developer.png" alt="Aplicaciones Moviles">
                        <div class="icon">
                            <i class="fas fa-desktop"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Aplicaciones Moviles</h3>
                        <p>desarrollo de aplicaciones moviles Android y IOS.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/consulting.png" alt="consultoria">
                        <div class="icon">
                            <i class="fas fa-chart-line"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Consultoria</h3>
                        <p>Realizamos todo tipo de consultorias para el area de Tecnologia.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/micro-service.png" alt="api micro servicios">
                        <div class="icon">
                            <i class="fas fa-anchor"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>API's & Micro Servicios</h3>
                        <p>desarrollamos servicios de acuerdo a sus necesidades.</p>
                    </div>
                </div>
            </div>
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-headphones-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Ui & Ux Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-mobile-alt"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Responsive Design</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <div class="icon">
                            <i class="fas fa-camera-retro"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Photography</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
            <!-- <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="assets/img/services-img1.jpg" alt="services-img">
                        <div class="icon">
                            <i class="far fa-life-ring"></i>
                        </div>
                    </div>
                    <div class="services-content">
                        <h3>Custom Support</h3>
                        <p>Lorem ipsum dolor sit amet consecte, consectetur adipiscing elit, sed do eiusmod tempor incididunt.</p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>
</div>
