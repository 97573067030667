<section class="how-work-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Como <span>Trabajamos</span></h2>
            <p>Utilizamos metodologias agiles de desarrollo, que nos ayuda a ser mas eficientes.</p>
        </div>
        <div class="row justify-content-center">
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-info-circle"></i>
                    </div>
                    <h3>Planificaci&oacute;n</h3>
                    <p>Propone un desarrollo basado en iteraciones, es decir, fases en las que se dividen los proyectos.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="fas fa-drafting-compass"></i>
                    </div>
                    <h3>Dise&ntilde;o</h3>
                    <p>Abarca muchas habilidades y disciplinas del dise&ntilde;o web incluyen dise&ntilde;o gráfico web; dise&ntilde;o de interfaz de usuario; autoría, incluido código estandarizado y software propietario; dise&ntilde;o de experiencia de usuario;.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6 col-sm-6">
                <div class="single-how-work">
                    <div class="icon">
                        <i class="far fa-paper-plane"></i>
                    </div>
                    <h3>Puesta en Producci&oacute;n</h3>
                    <p>Proceso donde es puesto a disposicion del cliente, el aplicativo en proyecto.</p>
                </div>
            </div>
        </div>
    </div>
    <div class="shape1"><img src="assets/img/shape1.png" alt="image"></div>
</section>
