<div class="cta-area ptb-100">
    <div class="container">
        <div class="cta-content">
            <div class="section-title mb-0">
                <h4>Buscas Servicios Exclusivos?</h4>
                <h2>Obtenga lo mejor para su negocio </h2>
                <p>Nuestro Equipo de expertos, esta disponible para brindarle el mejor servicio de consultoria.</p>
                <a (click)="onClick('contact')" class="btn btn-primary">Contactenos</a>
                <a (click)="onClick('work')" class="btn btn-primary view-work">Nuestros Trabajos</a>
            </div>
        </div>
    </div>
</div>
